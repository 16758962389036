<script setup lang="ts">
const route = useRoute()
const isCheckoutPage = computed(() => {
  return route.meta.pageType === 'Checkout'
})

const isMobileMenuOpen = ref(false)

const links = [
  {
    label: 'Nye varebiler',
    to: '/nye-varebiler',
    icon: 'BadgeIcon',
    click: () => isMobileMenuOpen.value = false,
  },
  {
    label: 'Brugte varebiler',
    to: '/brugte-varebiler',
    icon: 'VanIcon',
    click: () => isMobileMenuOpen.value = false,
  },
  {
    label: '+45 71 96 73 73',
    to: 'tel:+4571967373',
    icon: 'PhoneIcon',
  },
]

const showHelloCarBanner = ref(true)
</script>

<template>
  <div>
    <div v-if="!isCheckoutPage && showHelloCarBanner" class="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <div class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
        <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)" />
      </div>
      <div class="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl" aria-hidden="true">
        <div class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30" style="clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)" />
      </div>
      <div class="flex flex-wrap items-center gap-x-4 gap-y-2">
        <p class="text-sm leading-6 text-gray-900">
          <strong class="font-semibold">Vi har netop lanceret Hellocar.dk 🎉</strong><svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>Find din næste personbil her. Til privat og erhverv.
        </p>
        <a href="https://hellocar.dk" target="_blank" class="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">Besøg Hellocar.dk
          <Icon name="ArrowRightIcon" size="1em" class="ml-2" />
        </a>
      </div>
      <div class="flex flex-1 justify-end">
        <button type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]" @click="showHelloCarBanner = false">
          <span class="sr-only">Dismiss</span>
          <svg class="h-5 w-5 text-gray-900" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
          </svg>
        </button>
      </div>
    </div>

    <div
      :class="{
        'absolute left-0 right-0': isCheckoutPage,
      }"
      class="w-full mx-auto max-w-container sm:px-14 px-8"
    >
      <UHeader
        class="py-3 border-none"
        :ui="{
          container: 'flex items-center justify-between gap-3 h-[--header-height] px-0 sm:px-0 lg:px-0',
          button: {
            base: 'md:hidden',
            icon: {
              open: 'i-heroicons-bars-3-20-solid',
              close: 'i-heroicons-x-mark-20-solid',
            },
          },
          panel: {
            wrapper: 'fixed inset-0 z-50 overflow-y-auto container bg-background lg:hidden',
            header: 'px-4 sm:px-6',
            body: 'px-4 sm:px-6 pt-3 pb-6',
          },
        }"
      >
        <template #logo>
          <div class="flex items-center space-x-4">
            <img src="/images/kassebil-logo-fill.svg" alt="Kassebil.dk logo" class="h-10 w-10">

            <LogoBlack class="w-auto h-6" />
          </div>
        </template>

        <template #bottom>
          <UNavigationLinks
            v-if="isMobileMenuOpen"
            class="md:hidden py-3 text-black"
            :links="links"
            :ui="{
              wrapper: 'space-y-5',
              wrapperLevel: 'space-y-1.5',
              base: 'flex items-center gap-3.5 group',
              active: '',
              inactive: '',
              level: 'border-l -ml-px pl-4',
              icon: {
                base: 'w-6 h-auto flex-shrink-0',
              },
              badge: {
                base: 'rounded-full',
              },
              label: 'text-base font-regola-medium truncate',
            }"
          />
        </template>

        <template #right>
          <UHorizontalNavigation
            :links="links.filter(link => link.label !== '+45 71 96 73 73')"
            class="hidden md:flex"
            :ui="{
              container: 'flex items-center min-w-0 space-x-3',
              wrapper: 'relative w-auto flex items-center justify-between',
              base: 'text-base md:text-lg font-regola-medium font-normal rounded-xl py-2.5 ',
              inactive: 'text-black',
              before: 'before:absolute before:inset-x-0 before:inset-y-2 before:inset-px before:rounded-xl hover:before:bg-black/5 dark:hover:before:bg-gray-800/50',
              active: '',
              after: '',
              icon: {
                base: 'flex-shrink-0 w-6 h-6 mr-3',
                active: 'text-black',
                inactive: 'text-black',
              },
            }"
          />

          <UButton class="hidden sm:flex" size="xl" icon="PhoneIcon" color="primary" :ui="{ rounded: 'rounded-2xl' }" to="tel:+4571967373">
            71 96 73 73
          </UButton>

          <UButton
            size="xl"
            color="black"
            variant="ghost"
            :ui="{
              rounded: 'rounded-xl',
              color: {
                black: {
                  ghost: 'text-black dark:text-gray-200 hover:text-gray-900 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-gray-800 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
                },
              },
            }"
            class="md:hidden"
            :aria-label="`${isMobileMenuOpen ? 'Close' : 'Open'} Menu`"
            icon="BurgerMenuIcon"
            @click="isMobileMenuOpen = !isMobileMenuOpen"
          />
        </template>
      </UHeader>
    </div>

    <UMain
      class="flex-1"
      :class="{
        'pt-0': isCheckoutPage && !isMobileMenuOpen,
        'pt-24': isCheckoutPage && isMobileMenuOpen,
        'pt-6': !isCheckoutPage,
      }"
    >
      <slot />
    </UMain>

    <div v-if="!isCheckoutPage" class="w-full mx-auto max-w-container sm:px-14 px-8">
      <Footer />
    </div>
  </div>
</template>
