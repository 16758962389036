<script setup lang="ts">
const { myLayer } = useAppConfig()
</script>

<template>
  <footer aria-labelledby="footer-heading" class="bg-white">
    <p id="footer-heading" class="sr-only">
      Footer
    </p>
    <div class="mx-auto ">
      <div>
        <div class="pb-20 pt-16">
          <div class="mx-auto mt-16 max-w-5xl xl:grid xl:grid-cols-2 xl:gap-8">
            <div class="grid grid-cols-2 gap-8 xl:col-span-2">
              <div
                class="space-y-12 md:grid md:grid-cols-2 md:gap-8 md:space-y-0"
              >
                <div>
                  <p class="text-sm font-medium text-gray-900">
                    Kundeservice
                  </p>
                  <ul role="list" class="mt-6 space-y-6">
                    <li class="text-sm">
                      <NuxtLink
                        to="/om-os"
                        class="text-gray-500 hover:text-gray-600"
                      >
                        Kontakt
                      </NuxtLink>
                    </li>

                    <li class="text-sm text-gray-500">
                      Telefon: <br class="lg:hidden">
                      <a
                        :href="myLayer.name === 'hellocar' ? 'tel:+4530204269' : 'tel:+4571967373'"
                        class="text-primary-500 hover:underline"
                      >
                        {{ myLayer.name === 'hellocar' ? '+45 30 20 42 69' : '+45 71 96 73 73' }}

                      </a>
                    </li>
                    <li class="text-sm text-gray-500">
                      Email:
                      <NuxtLink
                        :to="myLayer.name === 'hellocar' ? 'mailto:mail@hellocar.dk' : 'mailto:mail@kassebil.dk'"
                        class="text-primary-500 hover:underline"
                      >
                        {{ myLayer.name === 'hellocar' ? 'mail@hellocar.dk' : 'mail@kassebil.dk' }}
                      </NuxtLink>
                    </li>
                    <li>
                      <a href="https://virksomhedsregister.finanstilsynet.dk/virksomhed-under-tilsyn.html?v=07D3FF5E-A0B3-EE11-A1E7-005056901D70" target="_blank">
                        <img
                          class="max-w-32"
                          :src="useCloudinary(
                            'https://res.cloudinary.com/dmzcdenki/image/upload/v1712059700/trust_eo2svz_c_crop_w_2045_ydoqdm.png', {
                              quality: 'auto',
                              format: 'webp',
                              resize: {
                                height: 150,
                              },
                            })"
                          alt="Godkendt"
                        >
                      </a>
                    </li>
                    <li>
                      <LazyTrustpilot />
                    </li>
                  </ul>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-900">
                    Åbningstider
                  </p>
                  <ul role="list" class="mt-6 space-y-6">
                    <li class="text-sm text-gray-500">
                      Alle dage 08:00 - 20:00
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="space-y-12 md:grid md:grid-cols-2 md:gap-8 md:space-y-0"
              >
                <div>
                  <p class="text-sm font-medium text-gray-900">
                    Virksomhed
                  </p>
                  <ul role="list" class="mt-6 space-y-6">
                    <li class="text-sm">
                      <NuxtLink
                        to="/om-os"
                        class="text-gray-500 hover:text-gray-600"
                      >
                        Hvem vi er
                      </NuxtLink>
                    </li>
                    <li class="text-sm">
                      <NuxtLink
                        to="/faq"
                        class="text-gray-500 hover:text-gray-600"
                      >
                        FAQ
                      </NuxtLink>
                    </li>

                    <li class="text-sm">
                      <NuxtLink
                        to="/karriere"
                        class="text-gray-500 hover:text-gray-600"
                      >
                        Karriere
                      </NuxtLink>
                    </li>
                    <li class="text-sm text-gray-500">
                      CVR: 41462582
                    </li>
                  </ul>
                </div>
                <div>
                  <p class="text-sm font-medium text-gray-900">
                    Andet
                  </p>
                  <ul role="list" class="mt-6 space-y-6">
                    <li class="text-sm">
                      <NuxtLink
                        to="/privatlivspolitik"
                        class="text-gray-500 hover:text-gray-600"
                      >
                        Cookiepolitik
                      </NuxtLink>
                    </li>
                    <li class="text-sm">
                      <NuxtLink
                        to="/blog"
                        class="text-gray-500 hover:text-gray-600"
                      >
                        Blog
                      </NuxtLink>
                    </li>
                    <slot name="other" />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="py-10 md:flex md:items-center md:justify-between">
        <div class="text-center md:text-left">
          <div class="flex items-center text-sm text-gray-500">
            <Icon name="ProtectIcon" class="mr-2" /> {{ new Date().getFullYear() }} Kassebil.dk - en del af
            <a
              class="text-primary-600 hover:text-primary-500 ml-1"
              href="https://dnc-group.dk"
              target="_blank"
              rel="noopener"
            >
              DNC Group.
            </a>
          </div>
        </div>

        <div class="mt-4 flex items-center justify-center md:mt-0">
          <div class="flex space-x-8">
            <a
              href="https://www.facebook.com/kassebil.dk"
              rel="noopener"
              target="_blank"
              class="text-gray-400 hover:text-gray-300"
            >
              <span class="sr-only">Facebook</span>
              <Icon name="FacebookIcon" size="1.5em" />

            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
